import cuid from 'cuid';
import type { OptionQuestionType, RowsQuestionType } from '@enums/Survey';
import { SurveyOptionType, SurveyQuestionType } from '@enums/Survey';
import type {
  SurveyQuestionOption,
  SurveyQuestionMatrixRow,
  SurveyQuestion,
  SurveySection,
  ConjointAnalysisQuestion,
  ExclusiveOptionsQuestion,
  SurveyQuestionOptionMetadata,
  SurveyRichText,
  SurveyMessage,
  OptionMetadata,
  RowMetadata,
  SurveyAlternateImageExercise,
} from '@/types/survey';
import { Remirror } from '@/types';
import { OptionQuestionTypes, RowQuestionTypes } from '@/containers/Survey/utils/questions';
import { convertToSurveyRichText } from '@/containers/Survey/utils';
import * as surveyBuilder from '../utils/question';

type GenerateNewQuestion = {
  identifier?: string;
  ordinal: number;
  section: {
    identifier: string;
  };
};

export const generateNewQuestion = (data: GenerateNewQuestion) => {
  return {
    attributes: [],
    base: {
      id: null,
      identifier: data.identifier || cuid(),
    },
    id: null,
    metadata: {
      canDelete: true,
      canModifyValue: true,
      template: {},
    },
    matrixRows: [],
    options: [],
    ordinal: data.ordinal,
    section: data.section,
    settings: {},
    typeId: null,
    value: generateEmptyRichText(),
  } as SurveyQuestion;
};

const generateEmptyRichText = (): SurveyRichText.RichTextValue => {
  return {
    type: Remirror.NodeType.Doc,
    content: [{
      content: [],
      type: Remirror.NodeType.Paragraph,
    }],
  };
};

export const generateNewMessage = (identifier: string): SurveyMessage => {
  return {
    metadata: {
      anchor: false,
      canDelete: true,
      canEdit: true,
      template: {},
    },
    id: null,
    identifier,
    value: generateEmptyRichText(),
  };
};

export const generateNewAIE = (identifier: string): SurveyAlternateImageExercise => {
  return {
    id: null,
    identifier,
    metadata: {
      canDelete: true,
      canModifyValue: true,
    },
    settings: {
      delaySeconds: null,
      randomize: false,
    },
    groups: [{
      ordinal: 1,
      identifier: cuid(),
      name: 'A',
      entries: [],
      settings: { randomize: true, anchor: false },
    }],
  };
};

export const generateNewSection = ({
  identifier = cuid(),
  ordinal,
}: GenerateNewSection): SurveySection => {
  return {
    hidden: false,
    id: null,
    identifier,
    isScreener: false,
    metadata: {
      canAddQuestion: true,
      canDelete: true,
      canModifyVisibility: true,
      randomize: false,
      randomizeSelf: false,
      template: {},
    },
    name: `Section ${ordinal}`,
    ordinal,
  };
};

type GenerateNewSection = {
  identifier?: string;
  ordinal: number;
};

type GenerateNewOption<T extends OptionQuestionType = OptionQuestionType> = {
  metadata?: OptionMetadata<T>;
  ordinal: number;
  type?: SurveyOptionType;
  value?: string;
};

export const generateDefaultOptionMetadata = <T extends OptionQuestionType = OptionQuestionType>(typeId: T): OptionMetadata<T> => {
  return DefaultOptionMetadataMap[typeId] as OptionMetadata<T>;
};

export const generateNewOption = <T extends OptionQuestionType = OptionQuestionType>({
  metadata,
  ordinal,
  type = SurveyOptionType.Default,
  value = '',
}: GenerateNewOption<T>, typeId: T): SurveyQuestionOption<OptionMetadata<T>> => {

  const defaultMetdata = generateDefaultOptionMetadata(typeId);

  return {
    base: {
      id: null,
      identifier: cuid(),
    },
    conditions: [],
    id: null,
    metadata: metadata || defaultMetdata,
    ordinal,
    type,
    value: convertToSurveyRichText(value),
  };
};

export const generateDefaultRowMetadata = <T extends RowsQuestionType = RowsQuestionType>(typeId: T): RowMetadata<T> => {
  return DefaultRowMetadataMap[typeId] as RowMetadata<T>;
};

export const generateNewMatrixRow = <T extends RowsQuestionType = RowsQuestionType>(ordinal: number, typeId: T): SurveyQuestionMatrixRow<RowMetadata<T>> => {

  const metadata = generateDefaultRowMetadata(typeId);

  return {
    base: {
      id: null,
      identifier: cuid(),
    },
    conditions: [],
    id: null,
    metadata,
    ordinal,
    value: generateEmptyRichText(),
  };
};

export function getNewAttribute(): ConjointAnalysisQuestion.Attribute {
  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    levels: generateLevels(surveyBuilder.conjointAnalysis.DefaultLevels),
    value: '',
  };
}

export function getNewLevel(): ConjointAnalysisQuestion.Level {
  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    value: '',
  };
}

const generateOptions = <T extends OptionQuestionType = OptionQuestionType>(count: number, typeId: T): SurveyQuestionOption<OptionMetadata<T>>[] => {

  return Array(count)
    .fill(0)
    .map((_, i) => generateNewOption<T>({
      ordinal: i + 1,
    }, typeId));
};

const generateMatrixRows = <T extends RowsQuestionType = RowsQuestionType>(count: number, typeId: T): SurveyQuestionMatrixRow<RowMetadata<T>>[] => {
  return Array(count)
    .fill(0)
    .map((_, i) => generateNewMatrixRow<T>(i + 1, typeId));
};

const generateAttributes = (count: number) => {
  return Array(count)
    .fill(0)
    .map((_, i) => getNewAttribute());
};

const generateLevels = (count: number) => {
  return Array(count)
    .fill(0)
    .map((_, i) => getNewLevel());
};

export function generateNewOrdinalsRemoval<T extends { ordinal: number }>(items: T[], ordinal: number) {
  type OrdinalMap = {
    [ordinal: number]: number;
  };

  return items.reduce<OrdinalMap>((ordinals, item) => {
    return {
      ...ordinals,
      [item.ordinal]: item.ordinal < ordinal
        ? item.ordinal
        : item.ordinal > ordinal
          ? item.ordinal - 1
          : null,
    };
  }, {});
}

export const getInitialAttributes = (typeId: SurveyQuestionType): ConjointAnalysisQuestion.Attribute[] => {
  if (typeId !== SurveyQuestionType.ConjointAnalysis) {
    return [];
  }

  return generateAttributes(surveyBuilder.conjointAnalysis.DefaultAttributes);
};

export const getInitialQuestionOptions = <T extends SurveyQuestionType = SurveyQuestionType>(typeId: T): SurveyQuestion<T>['options'] => {

  if (!OptionQuestionTypes.includes(typeId)) return [];

  const minOptions = DefaultNumberOfOptionsMap[typeId as OptionQuestionType] || 0;
  return generateOptions(minOptions, typeId as OptionQuestionType);
};

export const refreshOptionsAfterTypeChange = <T extends OptionQuestionType = OptionQuestionType>(options: SurveyQuestionOption[], typeId: T): SurveyQuestionOption<OptionMetadata<T>>[] => {
  // const newOptions = options
  //   .filter(f => !!f.value);
  const newOptions = [];

  const min = DefaultNumberOfOptionsMap[typeId];
  while (newOptions.length < min) {
    newOptions.push(generateNewOption({
      ordinal: newOptions.length + 1,
    }, typeId));
  }

  return newOptions;
};

export const refreshMatrixRowsAfterTypeChange = <T extends RowsQuestionType = RowsQuestionType>(rows: SurveyQuestionMatrixRow[], typeId: T): SurveyQuestionMatrixRow<RowMetadata<T>>[] => {
  const newRows = [];
  // rows
  //   .filter(f => !!f.value);

  while (newRows.length < surveyBuilder.matrixGrid.MinRows) {
    newRows.push(generateNewMatrixRow(newRows.length + 1, typeId));
  }

  return newRows;
};

export const getInitialMatrixRows = <T extends SurveyQuestionType = SurveyQuestionType>(typeId: T): SurveyQuestion<T>['matrixRows'] => {

  if (!RowQuestionTypes.includes(typeId)) {
    return [];
  }
  const minRows = surveyBuilder.matrixGrid.DefaultRows;
  const rows = generateMatrixRows(minRows, typeId as RowsQuestionType);

  return rows as SurveyQuestion<T>['matrixRows'];
};

export const getDefaultSettingsForType = <T extends SurveyQuestionType = SurveyQuestionType>(typeId: T): SurveyQuestion<T>['settings'] => {
  return DefaultSettingsMap[typeId];
};

export function fillDefaultExclusiveOptionsQuestion(question: SurveyQuestion<SurveyQuestionType.ExclusiveOptions>): SurveyQuestion<SurveyQuestionType.ExclusiveOptions> {
  const sections = Array.from({ length: 2 }).map<ExclusiveOptionsQuestion.OptionSection>((_, i) => ({
    ordinal: i + 1,
    value: '',
    identifier: cuid(),
    type: 'single-select',
    settings: getDefaultExclusiveOptionsSectionSettings(),
  }));

  let ordinal = 1;
  const options = sections.flatMap(s => {
    return Array.from({ length: 3 }).map((_, i) => {
      return generateNewOption({
        metadata: {
          canDelete: true,
          canModifyValue: true,
          isOpenEnded: false,
          isExclusive: false,
          template: {},
          sectionId: s.identifier,
        },
        ordinal: ordinal++,
        type: SurveyOptionType.Default,
      }, SurveyQuestionType.ExclusiveOptions);
    });
  });
  return {
    ...question,
    settings: {
      ...question.settings,
      optionSections: sections,
    },
    options,
  };
}

export function getDefaultExclusiveOptionsSectionSettings(): ExclusiveOptionsQuestion.SectionSettings {
  return {
    requireSelection: true,
    exclusiveGroups: [],
  };
}

const DefaultOptionMetadata: SurveyQuestionOptionMetadata = {
  canDelete: true,
  canModifyValue: true,
  template: {},
};

const DefaultOptionMetadataMap: OptionMetadataMap = {
  [SurveyQuestionType.MatrixGrid]: DefaultOptionMetadata,
  [SurveyQuestionType.MaxDifference]: DefaultOptionMetadata,
  [SurveyQuestionType.MultipleChoice]: surveyBuilder.multipleChoice.DefaultOptionMetadata,
  [SurveyQuestionType.Multiselect]: surveyBuilder.multiselect.DefaultOptionMetadata,
  [SurveyQuestionType.ExclusiveOptions]: surveyBuilder.exclusiveOptions.DefaultOptionMetadata,
  [SurveyQuestionType.Ranking]: surveyBuilder.ranking.DefaultOptionMetadata,
  [SurveyQuestionType.MatrixMultiselect]: DefaultOptionMetadata,
  [SurveyQuestionType.NumberInputTable]: surveyBuilder.numberInputTable.DefaultOptionMetadata,
  [SurveyQuestionType.Dropdown]: surveyBuilder.dropdown.DefaultOptionMetadata,
  [SurveyQuestionType.MatrixRange]: surveyBuilder.matrixRange.DefaultOptionMetadata,
};

const DefaultRowMetadataMap: RowMetadataMap = {
  [SurveyQuestionType.MatrixGrid]: surveyBuilder.matrixGrid.DefaultRowMetadata,
  [SurveyQuestionType.MatrixMultiselect]: surveyBuilder.matrixMultiselect.DefaultRowMetadata,
  [SurveyQuestionType.NumberInputTable]: surveyBuilder.numberInputTable.DefaultRowMetadata,
  [SurveyQuestionType.Sliders]: surveyBuilder.matrixSlider.DefaultRowMetadata,
  [SurveyQuestionType.MatrixRange]: surveyBuilder.matrixRange.DefaultRowMetadata,
};

const DefaultNumberOfOptionsMap: OptionsMap = {
  [SurveyQuestionType.MatrixGrid]: surveyBuilder.matrixGrid.DefaultColumns,
  [SurveyQuestionType.MaxDifference]: surveyBuilder.maxDiff.DefaultOptions,
  [SurveyQuestionType.MultipleChoice]: surveyBuilder.multipleChoice.DefaultOptions,
  [SurveyQuestionType.Multiselect]: surveyBuilder.multiselect.DefaultOptions,
  [SurveyQuestionType.Ranking]: surveyBuilder.ranking.DefaultOptions,
  [SurveyQuestionType.MatrixMultiselect]: surveyBuilder.matrixMultiselect.DefaultColumns,
  [SurveyQuestionType.NumberInputTable]: surveyBuilder.numberInputTable.DefaultColumns,
  [SurveyQuestionType.Dropdown]: surveyBuilder.dropdown.DefaultOptions,
  [SurveyQuestionType.ExclusiveOptions]: 0,
  [SurveyQuestionType.MatrixRange]: surveyBuilder.matrixRange.DefaultColumns,
};

const DefaultSettingsMap: SettingsMap = {
  [SurveyQuestionType.MultipleChoice]: surveyBuilder.multipleChoice.DefaultSettings,
  [SurveyQuestionType.Multiselect]: surveyBuilder.multiselect.DefaultSettings,
  [SurveyQuestionType.Ranking]: surveyBuilder.ranking.DefaultSettings,
  [SurveyQuestionType.MatrixGrid]: surveyBuilder.matrixGrid.DefaultSettings,
  [SurveyQuestionType.MaxDifference]: surveyBuilder.maxDiff.DefaultSettings,
  [SurveyQuestionType.ShortTextResponse]: surveyBuilder.shortText.DefaultSettings,
  [SurveyQuestionType.LongTextResponse]: surveyBuilder.longText.DefaultSettings,
  [SurveyQuestionType.ConjointAnalysis]: surveyBuilder.conjointAnalysis.DefaultSettings,
  [SurveyQuestionType.Sliders]: surveyBuilder.matrixSlider.DefaultSettings,
  [SurveyQuestionType.MatrixMultiselect]: surveyBuilder.matrixMultiselect.DefaultSettings,
  [SurveyQuestionType.NumberInputTable]: surveyBuilder.numberInputTable.DefaultSettings,
  [SurveyQuestionType.MultiTextbox]: surveyBuilder.multitextbox.DefaultSettings,
  [SurveyQuestionType.ExclusiveOptions]: surveyBuilder.exclusiveOptions.DefaultSettings,
  [SurveyQuestionType.Dropdown]: surveyBuilder.dropdown.DefaultSettings,
  [SurveyQuestionType.MatrixRange]: surveyBuilder.matrixRange.DefaultSettings,
  [SurveyQuestionType.ImageMarkup]: surveyBuilder.imageMarkup.DefaultSettings,
};

type OptionsMap<T extends OptionQuestionType = OptionQuestionType> = Record<T, number>;
type SettingsMap<T extends SurveyQuestionType = SurveyQuestionType> = Record<T, SurveyQuestion<T>['settings']>;
type OptionMetadataMap<T extends OptionQuestionType = OptionQuestionType> = Record<T, OptionMetadata<T>>;
type RowMetadataMap<T extends RowsQuestionType = RowsQuestionType> = Record<T, RowMetadata<T>>;