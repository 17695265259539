import { Redirect, Route, Switch } from 'react-router-dom';
import { pathname } from '@consts';
import { AdaptiveNavigation } from '@containers/AdaptiveNavigation';
import * as Prompt from '@containers/Platform.Prompt';
import { type Routing } from '@containers/Routing';
import { MainSubscriber } from '@containers/Subscriber';
import { useLoadZendeskScript } from '@containers/Zendesk';
import { CallFeedback } from '@/components/Modal/CallFeedback/CallFeedback';
import { ChatWidget } from '@/components/SupportChat';
import { useMainRoutes, AppGuard } from './routes';

export const Main = () => {
  const routes = useMainRoutes();

  useLoadZendeskScript();

  return (
    <MainSubscriber>
      <ChatWidget />
      <CallFeedback />
      <Prompt.OutlookCalendarIntegration />
      <Prompt.CompleteCoreUserInfo />
      <Prompt.InactiveContractConsent />
      <AdaptiveNavigation />
      <Switch>
        {routes.map((x: Routing.RouterProps) =>
          <Route
            key={String(x.path)}
            exact={x.exact}
            path={x.path}
            render={p =>
              <AppGuard
                {...p}
                config={x} />
            } />
        )}

        <Redirect to={pathname.Home} />
      </Switch>
    </MainSubscriber>
  );
};

export default Main;