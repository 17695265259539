import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as api from '@api';
import type * as API from '@api/interfaces';
import * as QK from '@consts/querykey';
import { CallRole, CallStatus, utils as enumutils } from '@enums';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import * as utils from '@/components/Conference.Participants/utils';
import { Checkbox } from '@/components/Checkbox';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { Modal, type ModalProps } from '@/components/Modal/Modal';
import { ConferenceParticipants, options as OptionsLookup } from './Conference.Participants';
import type { ParticipantItem, SearchResultItem } from './interfaces';
import styles from './style/Conference.Participants.Modal.css';

type Props = {
  offPlatform?: boolean;
  onSuccess?: (data?: Response) => unknown;
  statusId: CallStatus;
} & ICallId
  & IProjectId
  & Pick<ModalProps, 'onClose' | 'open'>;

export const ConferenceParticipantsModal = (props: Props) => {
  const pending = props.statusId === CallStatus.Pending;

  const [items, setItems] = useState<ParticipantItem[]>([]);
  const [notify, setNotify] = useState(!pending);
  const [keyword, setKeyword] = useState('');
  const [selected, setSelected] = useState<SearchResultItem[]>([]);

  const exclude = useMemo(() => {
    return items.map(x => x.email);
  }, [items]);

  const autosuggest = useQuery([
    QK.Projects.Access.Search.Get,
    exclude,
    keyword,
    props?.projectId,
  ], () => {
    return api.projects.access.search({
      exclude,
      keyword,
      projectId: props.projectId,
    });
  }, {
    enabled: !!keyword.trim() && !!props.projectId,
    refetchOnWindowFocus: false,
  });

  const query = useQuery([
    `get:projects/scheduling/participants`,
    props.callId,
    props.projectId,
  ], () => {
    return api.projects.scheduling.getCallParticipants({
      callId: props.callId,
      projectId: props.projectId,
    });
  }, {
    enabled: props.open,
    onSuccess: res => {
      const sorted = [...res.items].sort(utils.sortParticipants);

      setItems(sorted);
    },
    refetchOnWindowFocus: false,
  });

  const mutation = useMutation<void, unknown, Pick<Request, 'notify'>>([
    `put:calls/bookings/participants`,
    props.callId,
  ], ({ notify }) => {
    return api.calls.bookings.updateParticipants({
      callId: props.callId,
      notify,
      participants: items,
    });
  }, {
    onSuccess: res => {
      props.onSuccess?.(res);

      return res;
    },
  });

  const hasChanged = useMemo(() => {
    return items.length !== query.data?.items?.length
        || items.some(a => !query.data?.items?.some?.(b => a.email === b.email && a.roleId === b.roleId))
        || items.some(a => !query.data?.items?.some?.(b => a.email === b.email && !!a.scheduler === !!b.scheduler));
  }, [
    items,
    query.data,
  ]);

  const getItemDisabled = useCallback((item: ParticipantItem) => {
    return item.roleId === CallRole.PrimaryRespondent;
  }, []);

  const submitDisabled = useMemo(() => {
    return !items.some(x => x.roleId === CallRole.Scheduler && !!x.scheduler)
        || !hasChanged
        || query.isFetching;
  }, [
    hasChanged,
    items,
    query.isFetching,
  ]);

  const pastedEmailsQuery = useMutation([
    QK.Projects.Access.Search.Get,
    exclude,
    keyword,
    props?.projectId,
  ], (emails: string[]) => {
    return api.projects.access.search({
      emails,
      exclude,
      projectId: props.projectId,
    });
  });

  const isRoleValid = useCallback((item: SearchResultItem, role: CallRole) => {
    if (!props.offPlatform && item.offPlatform) return OptionsLookup.restricted.some(r => r.id === role);

    return true;
  }, [props.offPlatform]);

  const handlePaste = useCallback((emails: string[], role: CallRole) => {
    pastedEmailsQuery.mutateAsync(emails)
    .then(res => {
      const all = new Set([
        ...res.items.map(x => x.email),
        ...selected.map(x => x.email),
        ...exclude,
      ]);

      const additions = emails.reduce((acc, email) => {
        return all.has(email)
          ? acc
          : [...acc, toUnsavedOffPlat(email)];
      }, res.items);

      const invalidAdditions = additions.filter(item => !isRoleValid(item, role));

      if (invalidAdditions.length) {
        alert(`The following emails cannot be added with the ${enumutils.CallRole.getName(role)} role: \n\n${invalidAdditions.map(x => x.email).join('\n')}`);
        return;
      }

      setSelected([...selected, ...additions]);
    });
  }, [
    exclude,
    isRoleValid,
    pastedEmailsQuery,
    selected,
  ]);

  const search = {
    keyword,
    loading: pastedEmailsQuery.isLoading,
    results: autosuggest.data?.items || [],
    setKeyword,
  };

  return (
    <Modal
      disableEscapeClose={mutation.isLoading}
      disableOverlayClick={mutation.isLoading}
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.root}>
        <Header>Manage Participants</Header>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <ConferenceParticipants
              isRoleValid={isRoleValid}
              items={items}
              getItemDisabled={getItemDisabled}
              onChange={setSelected}
              onPaste={handlePaste}
              offPlatform={props.offPlatform}
              search={search}
              selected={selected}
              updater={setItems} />
            {!pending &&
              <div className={styles.checkbox}>
                <Checkbox
                  checked={notify}
                  onChange={(e, checked) => setNotify(checked)} />
                <div className={styles.message}>{copy.notify}</div>
              </div>}
          </div>
          <ButtonSet className={styles.footer}>
            <ButtonOutlined
              className={styles.btn}
              color="silver"
              disabled={mutation.isLoading}
              onClick={props.onClose}>
              Cancel
            </ButtonOutlined>
            <ButtonActivityIndicator
              color="primary"
              className={styles.btn}
              disabled={submitDisabled}
              implicitDisable={false}
              loading={mutation.isLoading}
              variant="brick"
              onClick={() => mutation.mutate({ notify })}>
              Save
            </ButtonActivityIndicator>
          </ButtonSet>
        </div>
      </div>
    </Modal>
  );
};

ConferenceParticipantsModal.displayName = 'Conference.Participants.Modal';

const copy = {
  notify: `Send notification to updated participants`,
};

type Request = API.Calls.Bookings.UpdateParticipants.Request;
type Response = API.Calls.Bookings.UpdateParticipants.Response;

const toUnsavedOffPlat = (email: string) => ({
  email,
  id: null,
  name: email,
  offPlatform: true,
});