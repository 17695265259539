import { Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import * as actions from '@actions';
import * as api from '@api';
import { useHydrateEffect } from '@containers/AppReadyState';
import { useLogout } from '@containers/AppStateEffect/hooks';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { Modal } from '@/components/Modal/Modal';
import { ButtonSet } from '@/components/Modal/ButtonSet';

type Props = unknown;

export const InactiveContractConsent = (props: Props) => {
  const [open, setOpen] = useState(false);
  const platform = useSelector<Store.State, Store.Platform>(state => state.platform, shallowEqual);

  const dispatch = useDispatch();
  const logout = useLogout();

  const mutation = useMutation([
    `post:users/consent/usage/inactive-contract`,
  ], (value: boolean) => {
    return api.users.consent.updateInactiveContractConsent({
      value,
    })
    .then(() => {
      dispatch(actions.platformAlertChanged({
        displayInactiveContractConsent: value,
      }));
      setOpen(false);
    });
  });

  useHydrateEffect(() => {
    if (platform?.alerts?.displayInactiveContractConsent) {
      setOpen(true);
    }
  });

  const handleAccept = useCallback(() => {
    mutation.mutate(true);
  }, [mutation]);

  const handleDecline = useCallback(() => {
    logout({
      sideEffects: true,
    });
    setOpen(false);
  }, [
    logout,
    setOpen,
  ]);

  return (
    <Fragment>
      {open &&
        <Modal
          disableEscapeClose
          disableOverlayClick
          hideCloseIcon
          open>
          <div style={{ fontSize: 20, fontFamily: `var(--font-semibold)`, color: `var(--gray-d)` }}>{copy.title}</div>
          <div style={{ margin: `20px 0` }}>{copy.body}</div>
          <ButtonSet>
            <ButtonOutlined
              onClick={handleDecline}
              style={{ width: 200 }}>
              Decline
            </ButtonOutlined>
            <ButtonActivityIndicator
              loading={mutation.isLoading}
              onClick={handleAccept}
              style={{ width: 200 }}>
              Accept
            </ButtonActivityIndicator>
          </ButtonSet>
        </Modal>}
    </Fragment>
  );
};

InactiveContractConsent.displayName = 'InactiveContractConsent';

const copy = {
  body: `READ THIS CONTRACT CAREFULLY.  BY CLICKING THE “ACCEPT” BOX  YOU ARE AGREEING TO ENTER INTO THIS SOFTWARE LICENSE AGREEMENT FOR TRINITY PARTNERS, LLC’S  (“TRINITY”) SOFTWARE OR CODE (“AGREEMENT”) IN AN ONLINE ELECTRONIC FORMAT AND TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, TO THE EXCLUSION OF ALL OTHER TERMS.  THIS IS A LEGAL AGREEMENT BETWEEN TRINITY AND YOU, THE END CUSTOMER, (“YOU,” “LICENSEE”) FOR THE LICENSE OF A LIMITED RIGHT TO USE THE SOFTWARE FOR EVALUATION PURPOSES, TOGETHER WITH THE ACCOMPANYING DOCUMENTATION AS SET FORTH HEREIN.  IF THE TERMS OF THIS AGREEMENT ARE CONSIDERED AN OFFER, ACCEPTANCE IS EXPRESSLY LIMITED TO SUCH TERMS. TRINITY DOES NOT AUTHORIZE THE DOWNLOADING, INSTALLATION OR USE OF THE SOFTWARE UNTIL LICENSEE HAS AGREED TO BE BOUND BY THE TERMS OF THIS AGREEMENT BY CLICKING ON THE "I ACCEPT THE TERMS OF THE LICENSE AGREEMENT" BUTTON BELOW. IF YOU DO NOT UNCONDITIONALLY AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, CLICK THE “CANCEL” BUTTON BELOW.`,
  title: `Software License Agreement`,
};