import { Routing } from '@containers/Routing';
import { RouteAuth } from '@enums';
import HandleCallback from '@/scenes/handle-callback';
import {
  admin,
  main,
  research,
  test,
  website,
} from './router';

// Note: order matters
export const routes: Routing.RouteConfig[] = [
  ...test.routes,
  // TODO: figure out way where switching in between root/website route doesn't cause un-mounting
  website.root,
  website.router,
  admin.router,
  research.router,
  {
    auth: RouteAuth.Ignore,
    component: HandleCallback,
    path: '/callback/:type/:provider',
  },
  main.router,
];