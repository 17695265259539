import { useCallback, useContext, useState, useMemo } from 'react';
import { format } from 'date-fns';
import { useMutation } from '@tanstack/react-query';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { useStepper } from '@utils';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { backfillProjectCallAccess } from '@api/projects.access';
import { ProjectAccess, ReadOnlyProjectAccess } from '$admin/components/Project.Access';
import { Checkbox } from '@/components/Checkbox';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import type { ModalProps } from '@/components/Modal/Modal';
import { Modal as RootModal } from '@/components/Modal/Modal';
import Toast from '@/components/Toast';
import { ProjectAccessMutationContext, ProjectAccessModalStepperContext } from './Context';
import type { Entry, MutationResult } from './interfaces';
import styles from './style/Project.Access.Modal.css';

type Props = {
  items: Entry[];
  onSuccess?: () => unknown;
} & IProjectId & Pick<ModalProps, 'onClose' | 'open'>;

export const ProjectAccessModal = (props: Props) => {
  const screens = useMemo(() => [ManageAccess, AddToUpcomingCallsConfirmation, AddToUpcomingCalls], []);
  const [Component, actions] = useStepper(screens);
  const [mutationResult, setMutationResult] = useState<MutationResult>();

  return (
    <ProjectAccessModalStepperContext.Provider value={{
      next: actions.next,
      onClose: props.onClose,
      onSuccess: props.onSuccess,
      items: props.items,
      mutationResult,
      setMutationResult,
      projectId: props.projectId,
    }}>
      <RootModal
        disableEscapeClose
        disableOverlayClick
        onClose={props.onClose}
        open={props.open}>
        <div className={styles.root}>
          <div className={styles.wrap}>
            <Component />
          </div>
        </div>
      </RootModal>
    </ProjectAccessModalStepperContext.Provider>
  );
};

const ManageAccess = () => {
  const { next, onSuccess, setMutationResult, ...ctx } = useContext(ProjectAccessModalStepperContext);

  const [items, setItems] = useState(ctx.items);
  const mutation = useContext(ProjectAccessMutationContext);

  const isInternalUser = useIsInternalUser();

  const handleSubmit = useCallback(() => {
    mutation.mutateAsync(items).then(result => {
      if (result?.upcomingCalls?.length) {
        setMutationResult(result);
        next();
      } else {
        onSuccess();
      }
    });
  }, [
    items,
    mutation,
    onSuccess,
    next,
    setMutationResult,
  ]);

  return (
    <>
      <div className={styles.main}>
        <Header className={styles.header}>Manage Access</Header>
        {isInternalUser ? (
          <ProjectAccess
            items={items}
            updater={setItems} />
        ) : <ReadOnlyProjectAccess items={items} />}
      </div>
      {isInternalUser && (
        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="black"
            disabled={mutation.isLoading}
            fontWeight="bold"
            onClick={ctx.onClose}>
            Cancel
          </ButtonOutlined>
          <ButtonActivityIndicator
            className={styles.btn}
            implicitDisable={false}
            loading={mutation.isLoading}
            onClick={handleSubmit}>
            Save
          </ButtonActivityIndicator>
        </ButtonSet>
      )}
    </>
  );
};

const AddToUpcomingCallsConfirmation = () => {
  const { next, onSuccess, ...ctx } = useContext(ProjectAccessModalStepperContext);
  return (
    <>
      <div className={styles.main}>
        <Header className={styles.header}>Add to Upcoming Calls</Header>
        <div>Do you want to add the members to all the upcoming calls?</div>
      </div>
      <ButtonSet className={styles.footer}>
        <ButtonOutlined
          className={styles.btn}
          color="black"
          fontWeight="bold"
          onClick={ctx.onClose}>
          Do Not Add
        </ButtonOutlined>
        <ButtonOutlined
          className={styles.btn}
          onClick={next}>
          Add To Upcoming Calls
        </ButtonOutlined>
      </ButtonSet>
    </>
  );
};

const AddToUpcomingCalls = () => {
  const { next, onSuccess, ...ctx } = useContext(ProjectAccessModalStepperContext);
  const [selectedCalls, setSelectedCalls] = useState(ctx.mutationResult.upcomingCalls.map(c => c.callId));

  const toggleCallSelect = useCallback((callId: number) => {
    setSelectedCalls(old => {
      if (old.includes(callId)) {
        return old.filter(x => x != callId);
      } else {
        return [...old, callId];
      }
    });
  }, []);

  const toggleMasterCallSelect = useCallback(() => {
    setSelectedCalls(old => {
      if (old.length === ctx.mutationResult.upcomingCalls.length) {
        return [];
      } else {
        return ctx.mutationResult.upcomingCalls.map(c => c.callId);
      }
    });
  }, [ctx.mutationResult.upcomingCalls]);

  const { mutate: backfill, isLoading } = useMutation(() => {
    return backfillProjectCallAccess({
      callIds: selectedCalls,
      usersToAdd: ctx.mutationResult.usersToUpdate,
      projectId: ctx.projectId,
    });
  }, {
    onSuccess: ctx.onClose,
    onError: () => {
      Toast.error({ title: 'There was an error adding the users to the calls' });
    },
  });

  return (
    <>
      <div className={styles.main}>
        <Header className={styles.header}>Upcoming Calls</Header>
        <div>Assign the participants to the upcoming calls</div>
        <div className={styles.backfillContainer}>
          <table className={styles.backfillTable}>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={selectedCalls.length === ctx.mutationResult.upcomingCalls.length}
                    indeterminate={(!!selectedCalls.length && selectedCalls.length != ctx.mutationResult.upcomingCalls.length)}
                    onClick={toggleMasterCallSelect} />
                </th>
                <th>Date</th>
                <th>Time</th>
                <th>Respondent</th>
              </tr>
            </thead>
            <tbody>
              {ctx.mutationResult.upcomingCalls.map(c => (
                <tr key={c.callId}>
                  <td><Checkbox checked={selectedCalls.includes(c.callId)} onChange={() => toggleCallSelect(c.callId)} /></td>
                  <td>{c.startTime ? format(c.startTime, 'LLL do') : ' - '}</td>
                  <td>{c.startTime ? `${format(c.startTime, 'p')} - ${format(c.endTime, 'p')}` : ' - '}</td>
                  <td>{c.respondent}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ButtonSet className={styles.footer}>
        <ButtonOutlined
          className={styles.btn}
          color="black"
          fontWeight="bold"
          onClick={ctx.onClose}>
          Cancel
        </ButtonOutlined>
        <ButtonActivityIndicator
          loading={isLoading}
          className={styles.btn}
          onClick={() => backfill()}>
          Save
        </ButtonActivityIndicator>
      </ButtonSet>
    </>
  );
};

ProjectAccessModal.displayName = 'Project.Access.Modal';