import { useState, useContext, useMemo, useCallback } from 'react';
import { useStepper } from '@utils';
import { useInvalidateFilesQueries } from '@/utils/api';
import type { ModalProps } from '@/components/Modal/Modal';
import { useModal } from '@/components/Modal/hooks';
import { Header, Modal } from '@/components/Modal';
import { PublishModalContext } from './context';
import { Transcripts } from './PublishModal.Transcripts';
import { Access } from './PublishModal.Access';
import { Notify } from './PublishModal.Notify';
import styles from './style/PublishModal.css';

type Props = Pick<ModalProps, 'open' | 'onClose'>;

export const PublishModal = ({ ...modalProps }: Props) => {
  const Screens = useMemo(() => [Transcripts, Access, Notify], []);
  const [Screen, actions, index] = useStepper(Screens);
  const [numTranscriptsPublished, setNumTranscriptsPublished] = useState<number>();

  const invalidateFilesQueries = useInvalidateFilesQueries();

  const onClose = useCallback(() => {
    invalidateFilesQueries();
    modalProps.onClose();
  }, [invalidateFilesQueries, modalProps]);

  return (
    <Modal {...modalProps}>
      <PublishModalContext.Provider value={{ actions, index, numScreens: Screens.length, numTranscriptsPublished, setNumTranscriptsPublished }}>
        <Screen onClose={onClose} />
      </PublishModalContext.Provider>
    </Modal>
  );
};

export const usePublishModal = () => useModal(PublishModal);