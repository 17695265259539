import { UserSettingsProps, Currencies } from '@/types';
import { getCurrencySymbol } from '@/utils';
import { Select } from '@/components/Select';
import { Input, NumberInput } from '@/components/Input';
import styles from './style.css';

export type PickerValue = Pick<UserSettingsProps, 'hourlyRate' | 'rateCurrency'>;

type Props = {
  className?: string;
  value: PickerValue;
  onChange: (val: PickerValue) => void;
};

const MIN_VAL = 0;
const MAX_VAL = 999.99;

export const Picker = ({ value, onChange }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.currency}>
        <Select
          value={(CurrencyOptions.find(o => o.id === value.rateCurrency) ?? buildUnknownCurrencyOption(value.rateCurrency)).displayVal}
          onSelect={v => onChange({ rateCurrency: v.id, hourlyRate: value.hourlyRate })}
          getItemValue={o => o.displayVal}
          options={CurrencyOptions} />
      </div>
      <div className={styles.value}>
        <NumberInput
          onValueChange={e => onChange({ hourlyRate: e.floatValue, rateCurrency: value.rateCurrency })}
          value={value.hourlyRate}
          isAllowed={val => (val.floatValue >= MIN_VAL && val.floatValue <= MAX_VAL)}
          min={MIN_VAL}
          max={MAX_VAL} />
      </div>
    </div>
  );
};

const CurrencyOptions = Currencies.CuratedCurrencies.map(code => ({
  id: code,
  displayVal: `${code} (${getCurrencySymbol(code)})`,
}));

function buildUnknownCurrencyOption(code: string) {
  return {
    id: code,
    displayVal: code,
  };
}