import { useState, useCallback, useMemo } from 'react';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { indexBy } from '@utils/array';
import { oxfordCommaJoin } from '@utils';
import { useSurveyBuilderState } from '@/containers/SurveyBuilder';
import type { SurveySection } from '@/types';
import { SortableTable } from '@/presentation/Tables';
import type { UseModalProps } from '@/components/Modal';
import { Modal, Header, useModal } from '@/components/Modal';
import { Checkbox } from '@/components/Checkbox';
import { Button } from '@/components/Button';
import styles from './style/Modal.Randomization.css';

type Props = UseModalProps;
export const SectionRandomizationModal = (props: UseModalProps) => {
  const [state, dispatch] = useSurveyBuilderState();
  const [sectionRandomization, setSectionRandomization] = useState<Record<string, boolean>>(buildSectionRandomization(state.survey.sections));

  const updateSectionRandomization = useCallback((section: SurveySection, randomize: boolean) => {
    setSectionRandomization(old => ({
      ...old,
      [section.identifier]: randomize,
    }));
  }, []);

  const applySettings = useCallback(() => {
    dispatch({
      type: 'batch-actions',
      actions: Object.entries(sectionRandomization).map(([k, v]) => ({
        type: 'section-metadata-updated',
        payload: {
          identifier: k,
          metadata: {
            randomizeSelf: v,
          },
        },
      })),
    });
    props.onClose();
  }, [dispatch, sectionRandomization, props]);

  const randomizedSectionText = useMemo(() => {
    const ordinals = Object.entries(sectionRandomization).filter(([k, v]) => !!v).map(([k, v]) => state.survey.sections.find(s => s.identifier === k));

    return oxfordCommaJoin(ordinals.map(s => s.ordinal.toString()), 'or');
  }, [sectionRandomization, state.survey.sections]);

  const CheckboxCell = useCallback((cellProps: CellContext<SurveySection, unknown>) => {
    const s = cellProps.row.original;
    return (
      <Checkbox checked={sectionRandomization[s.identifier]} onChange={e => updateSectionRandomization(s, e.target.checked)} />
    );
  }, [sectionRandomization, updateSectionRandomization]);

  const columns = useMemo<ColumnDef<SurveySection>[]>(() => {
    return [{
      id: 'checkbox',
      header: 'Randomize',
      cell: CheckboxCell,
    }, {
      id: 'order',
      header: 'Order',
      cell: props => sectionRandomization[props.row.original.identifier] ? randomizedSectionText : props.row.original.ordinal,
    }, {
      id: 'name',
      header: 'Name',
      cell: props => props.row.original.name ?? `Section ${props.row.original.ordinal}`,
    }];
  }, [CheckboxCell, randomizedSectionText, sectionRandomization]);

  const table = useReactTable({
    columns,
    data: state.survey.sections,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Modal {...props}>
      <Header>Randomize Sections</Header>
      <SortableTable table={table} />
      <div className={styles.buttons}>
        <Button
          variant='brick'
          color='destructive'
          onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          variant='brick'
          color='affirmative'
          disabled={Object.values(sectionRandomization).filter(x => x).length === 1}
          onClick={applySettings}>
          Update
        </Button>
      </div>
    </Modal>
  );
};

function buildSectionRandomization(sections: SurveySection[]) {
  return indexBy(sections, k => k.identifier, v => v.metadata?.randomizeSelf);
}

export const useSectionRandomizationModal = () => useModal(SectionRandomizationModal);