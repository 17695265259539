import http from '@services/http';
import { qs } from '@utils';
import type { Search } from './interfaces';

export const onplat = (data: Search.Users.OnPlat.Request) => {
  const q = qs.stringify(data, { arrayFormat: 'bracket' });

  return http.get<Search.Users.OnPlat.Response>(`/admin/search/users/onplat?${q}`);
};

export const xplat = (data: Search.Users.XPlat.Request) => {
  const q = qs.stringify(data, { arrayFormat: 'bracket' });

  return http.get<Search.Users.XPlat.Response>(`/admin/search/users/xplat?${q}`);
};