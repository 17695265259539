import http from '@services/http';
import { transformDates } from '@services/http/transformers';
import * as xform from '@transformers';
import type { WorkspaceBrand as WS } from './interfaces';

export const getBrand = (data: WS.GetBrand.Request): Promise<WS.GetBrand.Response> => {
  return http.get(`/workspaces/brands/${data.brandId}`, {
    transformResponse: transformDates,
  });
};

export const getProjects = (data: WS.GetProjects.Request) => {
  const { brandId, ...params } = data;

  return http.get(`/workspaces/brands/${data.brandId}/projects`, {
    params,
  })
    .then((res: WS.GetProjects.Response<string>) => ({
      items: res.items.map<WS.GetProjects.ProjectItem>(xform.normalizeProjectDates),
      pagination: res.pagination,
    }));
};

export const getProjectTracker = (data: WS.GetProjectTracker.Request): Promise<WS.GetProjectTracker.Response> => {
  return http.get(`/workspaces/brands/${data.brandId}/project-tracker`, {
    transformResponse: transformDates,
  });
};