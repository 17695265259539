import { useCallback, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { NumberParam, useQueryParam } from 'use-query-params';
import { path } from '@consts';
import { ButtonActivityIndicator } from '@presentation';
import { cx } from '@utils';
import { AuthForm } from '$website/components';
import { EnterpriseAuthContainer, PersistentLinkState } from '$website/containers';
import * as Auth from '$website/containers/Auth/interfaces';
import { useEnterpriseSubmit } from '$website/containers/Auth/hooks';
import { EnterpriseError } from '$website/screens/Auth/EnterpriseError';
import { Logo } from '@/components/Branding';
import LogoTrinity from 'static/images/logo-trinity-white.svg?url';
import styles from './style/Login.css';

type Params = {
  identity?: string;
};

const { Layout } = AuthForm;

function LoginEnterprise() {
  return (
    <EnterpriseAuthContainer>
      <LoginForm />
    </EnterpriseAuthContainer>
  );
}

function LoginForm() {
  const params = useParams<Params>();
  const [state, submit] = useEnterpriseSubmit();
  const [platform] = useQueryParam('platform', NumberParam);

  useEffect(() => {
    if (params?.identity) {
      submit({
        provider: params.identity,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = useCallback((values: Auth.Enterprise.Login.Form) => {
    submit(values);
  }, [submit]);

  const loginUrl = platform
    ? path.Website.PlatformLogin
    : path.Website.Login;

  return (
    <div className={cx(styles.bg, { [styles.platform]: !!platform })}>
      <div className={styles.col}>
        <div className={styles.logo}>
          <EnterpriseLoginLogo platform={!!platform} />
        </div>
        <div className={styles.form}>
          <Layout.Header>
            <Layout.Row className={cx(styles.row, { [styles.title]: !!platform })}>
              <Layout.Title>Enterprise Log In</Layout.Title>
            </Layout.Row>
          </Layout.Header>
          <div className={styles.body}>
            <Formik<Auth.Enterprise.Login.Form>
              initialValues={{ email: '' }}
              onSubmit={(values, actions) => {
                handleLogin(values);
              }}
              validationSchema={Schema}>
              <Form>
                <AuthForm.Enterprise.Login className={cx(styles.visitor, styles.row, styles.input)} />
                <div className={cx(styles.login, styles.row)}>
                  <EnterpriseError className={cx(styles.error)} />
                </div>
                <Layout.Row className={styles.row}>
                  <ButtonActivityIndicator
                    className={styles.submit}
                    loading={false}>
                    Continue
                  </ButtonActivityIndicator>
                </Layout.Row>
                <Layout.Row>
                  <div className={styles.otherStrategy}>
                    <Link to={loginUrl}>
                      <span>Not an enterprise user?</span>
                    </Link>
                  </div>
                </Layout.Row>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export const Enterprise = PersistentLinkState(LoginEnterprise, 'Login.Enterprise');

const Schema = Yup.object().shape({
  email: Yup.string().email('A valid email address is required'),
});

type EnterpriseLoginLogoProps = {
  platform: boolean;
};

const EnterpriseLoginLogo = (props: EnterpriseLoginLogoProps) => {
  if (props.platform) {
    return (
      <Link to={path.Website.PlatformLogin}>
        <img
          src={LogoTrinity}
          alt="Trinity"
          className={styles.logoImage} />
      </Link>
    );
  }

  return (
    <Link to={path.Website.Root}>
      <Logo.White className={styles.logoImage} />
    </Link>
  );
};

EnterpriseLoginLogo.displayName = 'Login.Enterprise.Logo';