import type { Application, Projects } from '@api/interfaces';
import * as consts from '@consts';
import * as enums from '@enums';
import * as xform from '@transformers/helpers';
import { safeJsonDate, slugify } from '@utils';
import type { Project, ProjectLink, Token, ProjectParent } from '@/types';
import { LegacyGroupProjectState } from '@/types';

type ResponseData = Pick<Application.FetchAppResponse<string>,
    'pipeline'
  | 'projectParents'
  | 'projects'>;
type PipelineData = Pick<Application.FetchAppResponse, 'pipeline'>;

type UnparsedProject = {
  [P in keyof Pick<Project, 'createdOn'>]: string;
} & Omit<Project, 'createdOn'>;

type UnparsedProjectParent = ProjectParent<string>;

const normalizeProject = ({ pipeline }: PipelineData) => (project: Project) => {
  const runTransforms = xform.compose<Project, UnparsedProject>(
    normalizeProjectDates,
    normalizeProjectUsers({ pipeline }),
    setProjectSlug,
  );

  return runTransforms<Project>(project);
};

const normalizeProjectParent = (projectParent: ProjectParent<string>) => {
  const runTransforms = xform.compose<ProjectParent, UnparsedProjectParent>(
    normalizeProjectParentDates,
  );

  return runTransforms<ProjectParent<string>>(projectParent);
};

const normalizeProjects = ({ pipeline, projects, projectParents }: ResponseData): Store.Projects => {
  const projectsMap = xform.toMap<number, Project>(projects.map(normalizeProject({ pipeline })));
  return Object.assign(projectsMap, {
    parents: xform.toMap<number, ProjectParent>(projectParents.map(normalizeProjectParent)),
  });
};

type DateProps<T = Date> = {
  createdOn: T;
  startDate?: T;
  targetCompleteDate?: T;
};

function normalizeProjectDates<T extends ObjectLike & DateProps<string>>(project: T): T & DateProps<Date> {
  return Object.assign(project, {
    createdOn: safeJsonDate(project.createdOn),
    startDate: safeJsonDate(project.startDate),
    targetCompleteDate: safeJsonDate(project.targetCompleteDate),
  });
}

function normalizeProjectParentDates(projectParent: UnparsedProjectParent) {
  return Object.assign(projectParent, {
    createdOn: safeJsonDate(projectParent.createdOn),
  });
}

function normalizeProjectUsers(state: PipelineData) {
  return (project: Project) => {
    /* For new project */
    if (!state.pipeline
      || !state.pipeline.project
      || !state.pipeline.project[project.id]
    ) {
      return Object.assign(project, { userIds: [] as number[] });
    }

    const records = state.pipeline.project[project.id].users;
    const userIds = Object.values(records).map(({ userId }) => userId);

    return Object.assign(project, { userIds });
  };
}

const setProjectSlug = (project: Project) => {
  return Object.assign(project, { slug: slugify(project) });
};

function createLinkUrl(token: Pick<Token, 'typeId' | 'value'>) {
  const baseUrl = process.env.FRONT_BASE_URL;
  const pathname = {
    [enums.TokenType.ProjectOnboard]: consts.pathname.PROJECT_INVITE,
    [enums.TokenType.Referral]: consts.path.Website.ReferralSignup,
  }[token.typeId] as string;

  return `${baseUrl}${pathname}/${token.value}`;
}

const assignLinkUrl = (link: {
  token: Pick<Token, 'typeId' | 'value'>;
}) => {
  const url = createLinkUrl(link.token);
  return Object.assign(link, { url });
};

const assignProjectLinkUrl = <T extends Pick<ProjectLink, 'token'>>(link: T): T & { url: string } => {
  const url = createLinkUrl(link.token);
  return Object.assign(link, { url });
};

function transformNewProject({ project }: Pick<Projects.CreateProjectResponse, 'project'>) {
  const transform = normalizeProject({ pipeline: null });

  return transform(project);
}

function transformNewProjectParent({ parent }: Pick<Projects.CreateProjectResponse<string>, 'parent'>) {
  return normalizeProjectParent(parent);
}

export {
  assignLinkUrl,
  assignProjectLinkUrl,
  normalizeProject,
  normalizeProjectDates,
  normalizeProjectParentDates,
  normalizeProjectUsers,
  normalizeProjects,
  transformNewProject,
  transformNewProjectParent,
};

export default {
  assignLinkUrl,
  normalizeProject,
  normalizeProjects,
};