import { useState, useContext, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { querykey, vancery } from '@consts';
import * as api from '@api';
import { ProjectAccess } from '@/access/admin/components/Project.Access';
import { groupBy } from '@/utils/array';
import { Header } from '@/components/Modal';
import ActivityIndicator from '@/components/ActivityIndicator';
import type { Entry } from '@/components/Project.Access/interfaces';
import { FilesTableContext } from './context';
import type { PublishScreenProps } from './interfaces';
import { Navigation } from './PublishModal.Navigation';
import styles from './style/PublishModal.css';

export const Access = (props: PublishScreenProps) => {
  const { projectId } = useContext(FilesTableContext);
  const accessQuery = useQuery({
    queryKey: [querykey.Projects.Access.Get, projectId], queryFn: () => {
      return api.projects.access.getProjectAccessUsers({ projectId });
    }, refetchOnMount: false, refetchOnWindowFocus: false, refetchOnReconnect: false,
  });

  const updateAccessMutation = useMutation({
    mutationFn: () => {
      return api.projects.access.updateProjectAccessUsers({
        items: [...accessState, ...hiddenItems.current],
        projectId,
      });
    },
  });

  const [accessState, setAccessState] = useState<Entry[]>(accessQuery?.data?.items ?? []);
  const hiddenItems = useRef<Entry[]>([]);

  useEffect(() => {
    if (accessQuery.data) {
      setAccessState(old => {
        if (old.length) {
          return old;
        } else {
          //Only let the user manage client like users, other users will get added back to the mutation later
          const grouped = groupBy(accessQuery.data.items, k => (k.offPlatform || vancery.internalEmailDomains.some(d => k.email.endsWith(d))) ? 'hide' : 'show');
          hiddenItems.current = grouped['hide'] ?? [];
          return grouped['show'] ?? [];
        }
      });
    }
  }, [accessQuery.data]);

  if (accessQuery.isInitialLoading) return <ActivityIndicator />;

  if (!accessState.length) {
    return (
      <div>
        There are no users to grant access to.
      </div>
    );
  }

  return (
    <>
      <Header>Grant Clients Access?</Header>
      <div className={styles.subheader}>Manage who can view the published transcripts. Granting access will allow clients to access the project, view, and download published transcripts.</div>
      <ProjectAccess
        items={accessState}
        updater={entries => setAccessState(entries)}
        showPrimary={false} />
      <Navigation
        onCancel={props.onClose}
        onNext={updateAccessMutation.mutateAsync}
        nextText='Grant Access' />
    </>
  );
};