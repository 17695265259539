import { useCallback, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { querykey } from '@/consts';
import { RateLimitingContext } from '@containers/RateLimiting/Context';
import { useInvalidateObjectChildrenQuery } from './workspace.objects';
import { useInvalidateSharedObjectsQuery } from './workspace.shared';
import { useInvalidateProjectFilesQuery } from './project.files';

export const useInvalidateFilesQueries = () => {
  const invalidateObjectChildrenQuery = useInvalidateObjectChildrenQuery();
  const invalidateSharedObjectsQuery = useInvalidateSharedObjectsQuery();
  const invalidateProjectFilesQuery = useInvalidateProjectFilesQuery();
  const { query } = useContext(RateLimitingContext);

  return useCallback(() => {
    invalidateObjectChildrenQuery();
    invalidateSharedObjectsQuery();
    invalidateProjectFilesQuery();
    query?.refetch();
  }, [
    invalidateObjectChildrenQuery,
    invalidateSharedObjectsQuery,
    invalidateProjectFilesQuery,
    query,
  ]);
};

export const useInvalidateFilePreviewQueries = () => {

  const queryClient = useQueryClient();

  return useCallback((data: IWorkspaceFileId) => {
    const filePreviewQueryKey = querykey.Workspaces.FilePreview.Base({ fileId: data.fileId });
    const projectFilePreviewQK = querykey.Projects.FilePreview.Base({ fileId: data.fileId });

    return Promise.all([
      queryClient.invalidateQueries({
        queryKey: [filePreviewQueryKey],
      }),
      queryClient.invalidateQueries({
        queryKey: [projectFilePreviewQK],
      }),
    ]);
  }, [
    queryClient,
  ]);
};

export const useInvalidateFolderQueries = () => {
  const queryClient = useQueryClient();

  return useCallback((data: IWorkspaceFolderId) => {
    const folderQK = querykey.Workspaces.Folder.Base({ folderId: data.folderId });

    return queryClient.invalidateQueries({
      queryKey: [folderQK],
    });
  }, [queryClient]);
};