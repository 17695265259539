import { useQuery } from '@tanstack/react-query';
import * as QK from '@consts/querykey';
import * as api from '@api';
import { ProjectAccessQueryContext } from './Context';

type Props = {
  children: React.ReactNode;
} & IProjectId;

export const ProjectAccessQueryContainer = (props: Props) => {
  const query = useQuery([
    QK.Projects.Access.Get,
    props.projectId,
  ], () => {
    return api.projects.access.getProjectAccessUsers({
      projectId: props.projectId,
    });
  }, {
    refetchOnWindowFocus: false,
  });

  return (
    <ProjectAccessQueryContext.Provider value={query}>
      {props.children}
    </ProjectAccessQueryContext.Provider>
  );
};

ProjectAccessQueryContainer.displayName = 'ProjectAccessQueryContainer';