import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CallRole, CallStatus } from '@enums';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import * as api from '$admin/api';
import type * as API from '$admin/api/interfaces';
import type { ParticipantItem } from '$admin/components/Scheduling/interfaces';
import { ConferenceParticipants } from '$admin/components/Conference.Participants';
import * as utils from '@/components/Conference.Participants/utils';
import { Checkbox } from '@/components/Checkbox';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Header } from '@/components/Modal/Header';
import { Modal, type ModalProps } from '@/components/Modal/Modal';
import styles from './style/Call.ManageParticipants.css';

type Props = {
  offPlatform?: boolean;
  onSuccess?: (data?: Response) => unknown;
  statusId: CallStatus;
} & ICallId
  & IProjectId
  & Partial<IUserId>
  & Pick<ModalProps, 'onClose' | 'open'>;

export const ManageParticipants = (props: Props) => {
  const pending = props.statusId === CallStatus.Pending;

  const [items, setItems] = useState<ParticipantItem[]>([]);
  const [notify, setNotify] = useState(!pending);

  const query = useQuery([
    `get:admin/projects/scheduling/participants`,
    props.callId,
    props.projectId,
  ], () => {
    return api.projects.scheduling.getCallParticipants({
      callId: props.callId,
      projectId: props.projectId,
    });
  }, {
    enabled: props.open,
    onSuccess: res => {
      const sorted = [...res.items].sort(utils.sortParticipants);

      setItems(sorted);
    },
    refetchOnWindowFocus: false,
  });

  const mutation = useMutation<void, unknown, Pick<Request, 'notify'>>([
    `put:admin/calls/bookings/participants`,
    props.callId,
  ], ({ notify }) => {
    return api.calls.bookings.updateParticipants({
      callId: props.callId,
      notify,
      participants: items,
    });
  }, {
    onSuccess: res => {
      props.onSuccess?.(res);

      return res;
    },
  });

  const hasChanged = useMemo(() => {
    return items.length !== query.data?.items?.length
        || items.some(a => !query.data?.items?.some?.(b => a.email === b.email && a.roleId === b.roleId))
        || items.some(a => !query.data?.items?.some?.(b => a.email === b.email && !!a.scheduler === !!b.scheduler));
  }, [
    items,
    query.data,
  ]);

  const getItemDisabled = useCallback((item: ParticipantItem) => {
    return item.roleId === CallRole.Respondent && !item.offPlatform
      ? item.id === props.userId
      : false;
  }, [props.userId]);

  const submitDisabled = useMemo(() => {
    return !items.some(x => x.roleId === CallRole.Scheduler && !!x.scheduler)
        || !hasChanged
        || query.isFetching;
  }, [
    hasChanged,
    items,
    query.isFetching,
  ]);

  return (
    <Modal
      disableEscapeClose={mutation.isLoading}
      disableOverlayClick={mutation.isLoading}
      onClose={props.onClose}
      open={props.open}>
      <div className={styles.root}>
        <Header>Manage Participants</Header>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <ConferenceParticipants
              items={items}
              getItemDisabled={getItemDisabled}
              offPlatform={props.offPlatform}
              updater={setItems} />
            {!pending &&
              <div className={styles.checkbox}>
                <Checkbox
                  checked={notify}
                  onChange={(e, checked) => setNotify(checked)} />
                <div className={styles.message}>{copy.notify}</div>
              </div>}
          </div>
          <ButtonSet className={styles.footer}>
            <ButtonOutlined
              className={styles.btn}
              color="silver"
              disabled={mutation.isLoading}
              onClick={props.onClose}>
              Cancel
            </ButtonOutlined>
            <ButtonActivityIndicator
              color="primary"
              className={styles.btn}
              disabled={submitDisabled}
              implicitDisable={false}
              loading={mutation.isLoading}
              variant="brick"
              onClick={() => mutation.mutate({ notify })}>
              Save
            </ButtonActivityIndicator>
          </ButtonSet>
        </div>
      </div>
    </Modal>
  );
};

ManageParticipants.displayName = 'Modal.Call.ManageParticipants';

const copy = {
  notify: `Send notification to updated participants`,
};

type Request = API.Calls.Bookings.UpdateParticipants.Request;
type Response = API.Calls.Bookings.UpdateParticipants.Response;