import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { path, pathname } from '@consts';
import { ButtonActivityIndicator } from '@presentation';
import { cx } from '@utils';
import { AuthButton, AuthForm } from '$website/components';
import { AuthContainer, PersistentLinkState, SocialAuthContainer } from '$website/containers';
import { useLoginSubmit, useSocialAuth } from '$website/containers/Auth/hooks';
import { LoginError } from '$website/screens/Auth/LoginError';
import LogoTrinity from 'static/images/logo-trinity-white.svg?url';
import styles from './style/Login.Platform.css';

const { Layout } = AuthForm;

type Props = unknown;

const LoginPlatform = (props: Props) => {
  return (
    <AuthContainer>
      <SocialAuthContainer>
        <Form />
      </SocialAuthContainer>
    </AuthContainer>
  );
};

const Form = (props: Props) => {
  const [form, submit] = useLoginSubmit();
  const social = useSocialAuth();

  const handleEmail = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit();
  }, [submit]);

  return (
    <div className={styles.bg}>
      <div className={styles.col}>
        <div className={styles.logo}>
          <img
            src={LogoTrinity}
            alt="Trinity"
            className={styles.logoImage} />
        </div>
        <div className={styles.form}>
          <Layout.Header>
            <Layout.Row className={styles.title}>
              <Layout.Title>Log In</Layout.Title>
            </Layout.Row>
          </Layout.Header>

          <div className={styles.body}>
            <form onSubmit={handleEmail}>
              <AuthForm.Email.Login className={cx(styles.visitor, styles.row, styles.input)} />

              <Layout.Row className={styles.row}>
                <div className={styles.forgot}>
                  <Link to={path.Website.PlatformForgotPassword}>
                    <span>Forgot your password?</span>
                  </Link>
                </div>
              </Layout.Row>

              <div className={cx(styles.login, styles.row)}>
                <LoginError className={cx(styles.error)} />
              </div>

              <Layout.Row className={styles.wrap}>
                <ButtonActivityIndicator
                  className={styles.submit}
                  loading={form.loading}>
                  Log In
                </ButtonActivityIndicator>
              </Layout.Row>
              <Layout.Row>
                <div className={styles.otherStrategy}>
                  <Link to={`${path.Website.EnterpriseLogin}?platform=1`}>
                    <span>Looking for enterprise login?</span>
                  </Link>
                </div>
              </Layout.Row>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Platform = PersistentLinkState(LoginPlatform, 'Login.Platform');