import * as pathname from '@consts/pathname';
import { extend, join } from '@utils/path';

const signup = join(pathname.Signup);

const PrivacyPolicy = {
  Root: pathname.PrivacyPolicy,
  Root2: pathname.PrivacyPolicy2,
};

const Signup = {
  Root: signup(),
};

const Terms = {
  Root: pathname.Terms,
  Root2: pathname.Terms2,
};

export const Website = extend({
  About:              `/about`,
  Careers:            `/careers`,
  CollaborationTools: `/collaboration-tools`,
  Compliance:         `/compliance`,
  Consulting:         `/consulting`,
  Contact:            `/contact`,
  ContactUs:          `/contact-us`,
  Corporate:          `/corporate`,
  CustomerResearch:   `/customer-research`,
  Demo:               `/demo`,
  Enterprise:         `/enterprise`,
  EnterpriseLogin:    `/enterprise/login`,
  FAQ:                `/faq`,
  ForgotPassword:     `/forgot-password`,
  Individuals:        `/individuals`,
  Investment:         `/investment`,
  Login:              `/login`,
  Logout:             `/logout`,
  Platform:               `/platform`,
  PlatformForgotPassword: `/platform/forgot-password`,
  PlatformLogin:          `/platform/login`,
  PlatformResetPassword:  `/platform/reset-password`,
  PlatformSignup:         `/platform/signup`,
  PrivacyPolicy,
  Pricing:            `/pricing`,
  ProductResearch:    `/product-research-guide`,
  Quant2Qual:         `/quant2qual`,
  ReferralSignup:     `/r/signup`,
  ResetPassword:      `/reset-password`,
  Root:               `/`,
  Signup,
  SnowflakeReport:    `/snowflake`,
  SurveyBestPractices:`/survey-best-practices`,
  Terms,
});