import { createElement, Fragment, useCallback } from 'react';
import { Redirect, type RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RouteAuth } from '@enums';
import { pathname } from '@consts';
import { useBuildReturnRoute } from '@routes/hooks/useBuildReturnRoute';
import { useSessionValidator } from '@routes/hooks/useSessionValidator';
import { useResolveRouteForNoMatch } from '@containers/Routing/hooks';
import type { RouteConfig } from '@routes/interfaces';
import { ActivityIndicator } from '@/components/ActivityIndicator';

type Props = {
  config: RouteConfig;
} & RouteComponentProps;

const selectIsEmailVerified = (state: Store.State) => state.user.meta.isEmailVerified;
const selectState = (state: Store.State) => state.user.state;
const selectWasActionLogout = (state: Store.State) => state.user.state.wasActionLogout;

export const AppGuard = ({
  config,
  history,
  location,
  match,
}: Props) => {

  const isEmailVerified = useSelector(selectIsEmailVerified);
  const state = useSelector(selectState);
  const returnRoute = useBuildReturnRoute();
  const wasActionLogout = useSelector(selectWasActionLogout);

  const resolveNoMatch = useResolveRouteForNoMatch();
  const validateSession = useSessionValidator();

  const renderRoute = useCallback(() => {
    return (
      <Fragment>
        {config.component
          ? createElement(config.component, {
            history,
            location,
            match,
          })
          : config.render
            ? config.render({
              history,
              location,
              match,
            })
            : null}
      </Fragment>
    );
  }, [
    config,
    history,
    location,
    match,
  ]);

  const handleNotAllowed = useCallback(() => {
    const resolvedLocation = resolveNoMatch();

    return resolvedLocation
        ? <Redirect to={resolvedLocation} />
        : null;
  }, [
    resolveNoMatch,
  ]);

  if (config?.auth === RouteAuth.Ignore) {
    return renderRoute();
  }

  if (!state.authenticated && !wasActionLogout) {
    return <Redirect to={returnRoute} />;
  }

  if (!state.initialized) {
    return <ActivityIndicator show />;
  }

  if (!isEmailVerified &&
      !location.pathname.startsWith(pathname.VERIFY_EMAIL)) {
    return <Redirect to={pathname.VERIFY_EMAIL} />;
  }

  // * route is hit
  if (!config) {
    return (
      <Redirect to={{
        pathname: pathname.Home,
      }} />
    );
  }

  const permitted = validateSession({
    permissions: config.permissions,
    roles: config.roles,
  });

  if (permitted) {
    return renderRoute();
  }

  return handleNotAllowed();
};

export default AppGuard;