import { useCallback, useMemo } from 'react';
import MUIMenuItem from '@mui/material/MenuItem';
import { CallRole, utils } from '@enums';
import { Select } from '$admin/components/Select';
import { Checkbox } from '@/components/Checkbox';
import { Picker } from '$admin/components/Project.Access/Project.Access.Picker';
import type { ConferenceParticipantsProps, ParticipantItem, SearchResultItem } from './interfaces';
import styles from './style/Conference.Participants.css';

type Props = {
  onPaste: (emails: string[], role: CallRole) => void;
} & ConferenceParticipantsProps;

export const ConferenceParticipants = ({ getItemDisabled, items, search, updater, ...props }: Props) => {

  const handleChangeRole = useCallback((item: ParticipantItem, value: CallRole) => {
    if (value === RemoveOptionId as CallRole) {
      const removed = items.filter(x => x.email !== item.email);

      updater(removed);

    } else {
      const updated = items.map(x => {
        const { scheduler, ...rest } = x;

        return rest.email === item.email
          ? { ...rest, roleId: value }
          : x;
      });

      updater(updated);
    }
  }, [
    items,
    updater,
  ]);

  const handleChangeModeratorScheduler = useCallback((item: ParticipantItem, checked: boolean) => {
    const updated = items.map(x => {
      const roleId = checked
        ? CallRole.Scheduler
        : CallRole.ModeratorAttendee;
      return x.email === item.email
        ? { ...x, roleId, scheduler: checked }
        : x;
    });

    updater(updated);
  }, [
    items,
    updater,
  ]);

  const { setKeyword } = search;

  const handleMultiAdd = useCallback((toAdd: Omit<ParticipantItem, 'roleId'>[], role: CallRole) => {
    const selected = toAdd.map(item => ({
      id: item.id,
      name: item.name,
      email: item.email,
      offPlatform: !!item.offPlatform,
      roleId: role,
    }));

    updater([...items, ...selected]);
    setKeyword('');
  }, [items, setKeyword, updater]);

  const totals = useMemo(() => {
    return {
      all: items.length,
      primarymods: items.filter(x => x.roleId === CallRole.Scheduler).length,
    };
  }, [items]);

  const getRoleDisabled = useCallback((item: ParticipantItem) => {
    if (totals.primarymods <= 1 && isPrimaryModerator(item.roleId)) return true;

    return getItemDisabled(item);
  }, [
    getItemDisabled,
    totals,
  ]);

  const getOptions = useCallback((item: ParticipantItem) => {
    if (!props.offPlatform && item.offPlatform) return options.restricted;

    return item.roleId === CallRole.Scheduler
      ? options.all.filter(x => x.id !== CallRole.ModeratorAttendee)
      : options.all.filter(x => x.id !== CallRole.Scheduler);
  }, [props.offPlatform]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.field}>
            <div className={styles.label}>Enter Name or Email</div>
            <Picker
              existingItems={items}
              isRoleValid={props.isRoleValid}
              onAdd={handleMultiAdd}
              onChange={props.onChange}
              onPaste={props.onPaste}
              roleOptions={options.massAdd}
              search={search}
              selected={props.selected} />
          </div>
          <div className={styles.table}>
            <div className={styles.header}>
              <div className={styles.name}>
                <div className={styles.label}>{`Attendee (${totals.all})`}</div>
              </div>
              <div className={styles.fields}>
                <div className={styles.role}>
                  <div className={styles.label}>Role</div>
                </div>
                <div className={styles.checkbox}>
                  <div className={styles.label}>Primary</div>
                </div>
              </div>
            </div>
            <div className={styles.items}>
              {items.map(x =>
                <div
                  className={styles.item}
                  key={x.email}>
                  <div className={styles.name}>{x.name?.trim?.() || x.email}</div>
                  <div className={styles.fields}>
                    <div className={styles.role}>
                      {x.roleId !== CallRole.PrimaryRespondent &&
                        <Select
                          classes={{
                            disabled: styles.disabled,
                            root: styles.select,
                          }}
                          disabled={getRoleDisabled?.(x)}
                          onChange={e => handleChangeRole(x, +e.target.value)}
                          options={getOptions(x)}
                          value={x.roleId}>
                          <MUIMenuItem
                            key={RemoveOptionId}
                            style={{
                              borderTop: `1px solid var(--sec-01)`,
                              minHeight: 36,
                              cursor: 'pointer',
                            }}
                            value={RemoveOptionId}>
                            {RemoveOptionName}
                          </MUIMenuItem>
                        </Select>}
                      {x.roleId === CallRole.PrimaryRespondent &&
                        <div className={styles.primary}>
                          {utils.CallRole.getName(x.roleId)}
                        </div>}
                    </div>
                    <div className={styles.checkbox}>
                      {roleIsModeratorLike(x.roleId) &&
                        <Checkbox
                          checked={isPrimaryModerator(x.roleId)}
                          disabled={totals.primarymods <= 1 && isPrimaryModerator(x.roleId)}
                          disabledColor="var(--gray-l)"
                          onChange={(e, checked) => handleChangeModeratorScheduler(x, checked)} />}
                    </div>
                  </div>
                </div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConferenceParticipants.displayName = 'Conference.Participants';

const RemoveOptionId = 0;
const RemoveOptionName = 'Remove';

const makeOption = (role: CallRole) => ({
  id: role,
  name: utils.CallRole.getName(role),
});

export const options = {
  all: [
    makeOption(CallRole.Scheduler),
    makeOption(CallRole.Respondent),
    makeOption(CallRole.Attendee),
    makeOption(CallRole.ModeratorAttendee),
  ],
  restricted: [
    makeOption(CallRole.Respondent),
    makeOption(CallRole.Attendee),
  ],
  massAdd: [
    makeOption(CallRole.Respondent),
    makeOption(CallRole.Attendee),
    makeOption(CallRole.ModeratorAttendee),
  ],
};

const isPrimaryModerator = (roleId: CallRole) => {
  return [
    CallRole.Scheduler,
  ].includes(roleId);
};

const roleIsModeratorLike = (roleId: CallRole) => {
  return [
    CallRole.Scheduler,
    CallRole.ModeratorAttendee,
  ].includes(roleId);
};