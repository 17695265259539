import qs from 'query-string';
import http from '@services/http';
import type { User } from '@/types';
import { safeJsonDate } from '@utils';
import type { Users } from './interfaces';

export * as privacy from './users.privacy';
export * as resumes from './users.resumes';

export const deleteUserAccount = (data: IUserId) => {
  return http.delete(`/admin/users/${data.userId}`);
};

export const getUserByEmail = (data: Pick<User['contact'], 'email'>) => {
  return http.get(`/admin/users?email=${data.email}`);
};

export const getInternal = (): Promise<Users.GetInternalUsers.Response> => {
  return http.get(`/admin/users/internal`);
};

export const getDetails = (data: Users.GetUserDetails.Request) => {
  return http.get(`/admin/users/${data.userId}/details`)
    .then((user: Users.GetUserDetails.Response) => {
      return {
        ...user,
        createdOn: safeJsonDate(user.createdOn),
        identities: user.identities.map(m => ({
          ...m,
          createdOn: safeJsonDate(m.createdOn),
          lastLogin: safeJsonDate(m.lastLogin),
        })),
      };
    });
};

export const fetchEmailVerificationState = (data: Users.FetchEmailReverificationState.Request) => {
  return http.get<Users.FetchEmailReverificationState.Response<string>>(`/admin/users/${data.userId}/reverification`)
  .then(res => ({
    emailReverificationRequired: res?.emailReverificationRequired,
    lastVerifiedOn: safeJsonDate(res?.lastVerifiedOn),
  }));
};

export const fetchChatUsage = (data: Users.FetchChatUsage.Request) => {
  return http.get<Users.FetchChatUsage.Response>(`/admin/users/${data.userId}/usage`);
};

export const fetchContactInfo = (data: Users.FetchUserContactInfo.Request): Promise<Users.FetchUserContactInfo.Response> => {
  return http.get(`/admin/users/${data.userId}/contact-info`);
};

export const fetchPayoutInfo = (data: Users.FetchUserPayoutInfo.Request): Promise<Users.FetchUserPayoutInfo.Response> => {
  return http.get(`/admin/users/${data.userId}/payout-info`)
    .then((data: Users.FetchUserPayoutInfo.Response<string>) => {
      return {
        ...data,
        lastUpdatedOn: safeJsonDate(data.lastUpdatedOn),
      };
    });
};

export const fetchWorkingHours = (data: Users.FetchWorkingHours.Request) => {
  return http.get<Users.FetchWorkingHours.Response>(`/admin/users/${data.userId}/working-hours`);
};

export const syncTipaltiDetails = (data: Users.SyncTipaltiDetails.Request): Promise<Users.SyncTipaltiDetails.Response> => {
  return http.post(`/admin/users/${data.userId}/sync-tipalti-details`, {});
};

export const saveContactInfo = (data: Users.SaveUserContactInfo.Request): Promise<Users.SaveUserContactInfo.Response> => {
  return http.put(`/admin/users/${data.userId}/contact-info`, data);
};

export const savePayoutInfo = (data: Users.SaveUserPayoutInfo.Request): Promise<Users.SaveUserPayoutInfo.Response> => {
  return http.put(`/admin/users/${data.userId}/payout-info`, data);
};

export const autocomplete = (data: Users.FillUserAutocomplete.Request): Promise<Users.FillUserAutocomplete.Response> => {
  return http.get(`/admin/users/autocomplete?${qs.stringify(data, { arrayFormat: 'bracket' })}`);
};

export const toggleEnabled = (data: Users.ToggleUserEnabled.Request) => {
  return http.post(`/admin/users/${data.userId}/enabled`, {
    enabled: data.enabled,
  });
};

export const updateEmailReverification = (data: Users.UpdateEmailReverification.Request) => {
  return http.put<Users.UpdateEmailReverification.Response<string>>(`/admin/users/${data.userId}/reverification`, {
    verified: data.verified,
  })
  .then(res => ({
    emailReverificationRequired: res?.emailReverificationRequired,
    lastVerifiedOn: safeJsonDate(res?.lastVerifiedOn),
  }));
};

export const updateUserRoles = ({ userId, ...data }: Users.UpdateUserRoles.Request): Promise<Users.UpdateUserRoles.Response> => {
  return http.patch(`/admin/users/${userId}/roles`, data);
};

export const updateUserPermissions = ({ userId, ...data }: Users.UpdateUserPermissions.Request): Promise<Users.UpdateUserPermissions.Response> => {
  return http.patch(`/admin/users/${userId}/permissions`, data);
};

export const syncUserToHubspot = (userIds: IUserId[]) => {
  return http.put(`/admin/users/sync-to-hubspot`, userIds);
};

export const updateUserTeam = ({ userId, ...data }: Users.UpdateUserTeam.Request): Promise<Users.UpdateUserTeam.Response> => {
  return http.patch(`/admin/users/${userId}/team`, data);
};

export const updateUserSettings = (data: Users.UpdateUserSettings.Request): Promise<Users.UpdateUserSettings.Response> => {
  return http.patch(`/admin/users/${data.userId}/settings`, data);
};

export const updateWorkingHours = (data: Users.UpdateWorkingHours.Request) => {
  return http.put<Users.UpdateWorkingHours.Response>(`/admin/users/${data.userId}/working-hours`, data);
};

export const updateUserSpecialties = ({ userId, ...data }: Users.UpdateUserSpecialties.Request): Promise<Users.UpdateUserSpecialties.Response> => {
  return http.patch(`/admin/users/${userId}/specialties`, data);
};