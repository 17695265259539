import http from '@services/http';
import { nullableDate, qs } from '@utils';
import * as xform from './transformers';
import { Projects } from './interfaces';

export const findRespondentUnconfirmedCalls = (data: Projects.Scheduling.FindRespondentUnconfirmedCalls.Request) => {
  return http.get<Projects.Scheduling.FindRespondentUnconfirmedCalls.Response<string>>(`/admin/projects/${data.projectId}/scheduling/respondents/${data.userId}/unconfirmed`)
  .then(xform.projects.transformRespondentUnconfirmedCalls);
};

export const findSchedulerCalendars = (data: Projects.Scheduling.FindSchedulerCalendars.Request) => {
  const moderatorIds = data.moderatorIds?.length
      ? { moderatorIds: data.moderatorIds }
      : {};

  const q = data.callId
      ? `?${qs.stringify({ callId: data.callId, ...moderatorIds }, { arrayFormat: 'bracket' })}`
      : data.userIds
        ? `?${qs.stringify({ userIds: data.userIds, ...moderatorIds }, { arrayFormat: 'bracket' })}`
        : '';

  return http.get<Projects.Scheduling.FindSchedulerCalendars.Response<string>>(`/admin/projects/${data.projectId}/scheduling/calendars${q}`)
  .then(xform.projects.transformSchedulerCalendars);
};

export const getCallParticipants = (data: Projects.Scheduling.GetCallParticipants.Request) => {
  const q = data.callId
      ? `?${qs.stringify({ callId: data.callId })}`
      : data.respondentId
        ? `?${qs.stringify({ respondentId: data.respondentId })}`
        : '';

  return http.get<Projects.Scheduling.GetCallParticipants.Response>(`/admin/projects/${data.projectId}/scheduling/participants${q}`);
};

export const getCallSettings = (data: Projects.Scheduling.GetCallSettings.Request) => {
  return http.get<Projects.Scheduling.GetCallSettings.Response>(`/admin/projects/${data.projectId}/scheduling/settings?${qs.stringify({
    callId: data.callId,
  })}`);
};

export const notifyCallParticipants = (data: Projects.Scheduling.NotifyCallParticipants.Request) => {
  const { projectId, ...params } = data;
  return http.post<Projects.Scheduling.NotifyCallParticipants.Response>(`/admin/projects/${projectId}/scheduling/notifications`, params);
};

export const requestAvailability = (data: Projects.Scheduling.RequestAvailability.Request) => {
  const { projectId, ...params } = data;
  return http.post<Projects.Scheduling.RequestAvailability.Request>(`/admin/projects/${projectId}/scheduling/request-availability`, params);
};

export const scheduleCall = (data: Projects.Scheduling.ScheduleCall.Request) => {
  const { projectId, ...params } = data;
  return http.post<Projects.Scheduling.ScheduleCall.Response<string>>(`/admin/projects/${projectId}/scheduling/confirmations`, params)
  .then(res => ({
    call: {
      ...res.call,
      approvedOn: nullableDate(res.call.approvedOn),
      createdAt: nullableDate(res.call.createdAt),
      screenedOn: nullableDate(res.call.screenedOn),
      timeEnd: nullableDate(res.call.timeEnd),
      timeStart: nullableDate(res.call.timeStart),
    },
    conference: {
      details: {
        ...res.conference.details,
        endedOn: nullableDate(res.conference.details.endedOn),
        startedOn: nullableDate(res.conference.details.startedOn),
      },
      settings: res.conference.settings,
    },
    participants: res.participants,
    tags: res.tags,
  }));
};