import qs from 'query-string';
import http, { xhr } from '@services/http';
import * as enums from '@enums';
import type { Projects } from '@services/api/interfaces';
import * as xform from '@transformers';

export * as access from './projects.access';
export * as billing from './projects.billing';
export * as conference from './projects.conference';
export * as entities from './projects.entities';
export * as files from './projects.files';
export * as insights from './projects.insights';
export * from './projects.reports';
export * from './projects.surveys';
export * as pipeline from './pipeline';
export * as externalSourcing from './projects.external-sourcing';
export * as external from './projects.external-survey';
export * as scheduling from './projects.scheduling';
export * as transcripts from './projects.transcripts';

export const createProject = (data: Projects.CreateProjectRequest) => {
  return http.post('/projects', data)
    .then((data: Projects.CreateProjectResponse<string>) => ({
      metrics: data.metrics,
      object: xform.normalizeWorkspaceObject(data.object),
      parent: xform.transformNewProjectParent({ parent: data.parent }),
      project: xform.transformNewProject({ project: data.project }),
    }));
};

export const fetchProject = (data: Projects.FetchProject.Request): Promise<Projects.FetchProject.Response> => {
  return http.get<Projects.FetchProject.Response<string>>(`/projects/${data.projectId}`)
    .then(res => ({
      breadcrumbs: res.breadcrumbs,
      features: res.features,
      pipeline: res.pipeline,
      project: xform.normalizeProjectDates(res.project),
      state: {
        capabilities: res.state.capabilities,
        latest: xform.normalizeProjectDates(res.state.latest),
        object: xform.normalizeProjectDates(res.state.object),
        parent: xform.transformNewProjectParent({ parent: res.state.parent }),
        project: xform.normalizeProjectDates(res.state.project),
        versions: res.state.versions.map(xform.normalizeProjectDates),
        workspace: xform.normalizeProjectDates(res.state.workspace),
      },
      survey: res.survey,
    }));
};

export const fetchProjects = (data: Projects.FetchProjects.Request) => {
  return http.get<Projects.FetchProjects.Response>(`/projects`, {
    params: data,
  })
    .then((res: Projects.FetchProjects.Response<string>) => ({
      items: res.items.map<Projects.FetchProjects.ProjectItem>(xform.normalizeProjectDates),
      pagination: res.pagination,
    }));
};

export const fetchCalls = ({ projectId, ...data }: Projects.FetchCalls.Request) => {
  return http.get<Projects.FetchCalls.Response>(`/projects/${projectId}/calls`, {
    params: {
      index: data.index,
      size: data.size,
    },
  });
};

export const fetchRespondents = ({ projectId, ...data }: Projects.FetchRespondents.Request) => {
  return http.get<Projects.FetchRespondents.Response>(`/projects/${projectId}/respondents`, {
    params: {
      index: data.index,
      size: data.size,
    },
  });
};

const fetchProjectGoal = (data: Projects.FetchProjectGoalRequest): Promise<Projects.FetchProjectGoalResponse> => {
  return http.get(`/projects/${data.projectId}/goals`);
};

const fetchTargetCompany = (data: Projects.FetchTargetCompanyRequest): Promise<Projects.FetchTargetCompanyResponse> => {
  return http.get(`/projects/${data.projectId}/target-company`);
};

export const updateProjectOverview = (data: Projects.UpdateProjectOverview.Request) => {
  return http.patch(`/projects/${data.projectId}`, data)
    .then((res: Projects.UpdateProjectOverview.Response) => ({
      ...res,
      object: xform.normalizeWorkspaceObject(res.object),
    }));
};

const getProjectLink = (data: Projects.GetProjectLinkRequest): Promise<Projects.GetProjectLinkResponse> => {
  const { projectId, ...rest } = data;
  return http.get<Projects.GetProjectLinkResponse>(`/projects/${projectId}/links?${qs.stringify(rest)}`)
    .then(link => xform.assignProjectLinkUrl(link));
};

const sendSelfSourceProjectInviteEmails = (data: Projects.ProjectEmailInviteRequest): Promise<void> => {
  const { projectId, ...rest } = data;
  return http.post(`/projects/${projectId}/links/invite`, {
    ...rest,
    typeId: enums.ProjectLinkType.SelfSource,
  });
};

const sendConsultantRefererProjectInviteEmail = (data: Projects.ProjectEmailInviteRequest): Promise<void> => {
  const { projectId, ...rest } = data;
  return http.post(`/projects/${projectId}/links/invite`, {
    ...rest,
    typeId: enums.ProjectLinkType.ConsultantReferral,
  });
};

const updateProjectTargetCompany = (data: Projects.UpdateTargetCompanyRequest): Promise<Projects.UpdateTargetCompanyResponse> => {
  return http.put(`/projects/${data.projectId}/target-company`, data);
};

export const updateStatus = ({
  projectId,
  ...body
}: Projects.UpdateProjectStatus.Request): Promise<Projects.UpdateProjectStatus.Response> => {
  return http.patch(`/projects/${projectId}/status`, body);
};

export const cloneProject = ({
  projectParentId,
  ...body
}: Projects.CloneProject.Request) => {
  return http.post(`/projects/parents/${projectParentId}/clone`, body)
    .then((res: Projects.CloneProject.Response) => ({
      metrics: res.metrics,
      parent: xform.transformNewProjectParent({ parent: res.parent }),
      pipeline: xform.normalizePipeline(res.pipeline),
      previous: {
        pipeline: xform.normalizePipeline(res.previous.pipeline),
        project: xform.transformNewProject({ project: res.previous.project }),
      },
      project: xform.transformNewProject({ project: res.project }),
    }));
};

export const updateProjectSummary = ({ projectId, ...body }: Projects.UpdateProjectSummary.Request) => {
  return http.patch(`/projects/${projectId}/summary`, body);
};

export const downloadProjectSummary = (data: Projects.DownloadProjectSummary.Request): Promise<Projects.DownloadProjectSummary.Response> => {
  return http.get(`/projects/${data.projectId}/download-summary`, { responseType: 'blob' });
};

export const getProjectSummaryCalls = (data: Projects.GetProjectSummaryCalls.Request) => {
  return http.get<Projects.GetProjectSummaryCalls.Response>(`/projects/${data.projectId}/summary-calls`)
    .then(data => ({
      calls: data.calls.map(c => xform.normalizeCompletedCall(c)),
      features: data.features,
    }));
};

export const getProjectMetrics = (data: Projects.GetProjectMetrics.Request) => {
  return xhr.get<Projects.GetProjectMetrics.Response>(`/projects/${data.projectId}/metrics`);
};

export const getProjectParent = (data: Projects.GetProjectParent.Request) => {
  return http.get<Projects.GetProjectParent.Response>(`/projects/parents/${data.projectParentId}`);
};

export const updateProjectDisplayrDashboard = ({ projectId, ...body }: Projects.UpdateDisplayrDashboard.Request) => {
  return http.patch<Projects.UpdateDisplayrDashboard.Response>(`/projects/${projectId}/displayr-dashboard`, body);
};

export const getProjectSurveySettings = (data: Projects.GetProjectSurveySettings.Request) => {
  return http.get<Projects.GetProjectSurveySettings.Response>(`/projects/${data.projectId}/survey-settings`);
};

export const updateProjectSurveySettings = (data: Projects.UpdateProjectSurveySettings.Request) => {
  return http.patch<Projects.UpdateProjectSurveySettings.Response>(`/projects/${data.projectId}/survey-settings`, data);
};

export const getProjectClips = ({ projectId, ...params }: Projects.GetProjectClips.Request) => {
  return http.get<Projects.GetProjectClips.Response>(`/projects/${projectId}/clips?${qs.stringify(params, { arrayFormat: 'bracket' })}`);
};

export const getProjectTranscripts = (data: Projects.GetProjectTranscripts.Request) => {
  return http.get<Projects.GetProjectTranscripts.Response>(`/projects/${data.projectId}/transcripts`);
};

export const getProjectTranscriptGroupTags = (data: Projects.GetProjectTranscriptGroupTags.Request) => {
  return http.get<Projects.GetProjectTranscriptGroupTags.Response>(`/projects/${data.projectId}/transcript-group-tags`);
};

const getUsersToNotify = (data: Projects.GetUsersToNotify.Request) => {
  return http.get<Projects.GetUsersToNotify.Response>(`/projects/${data.projectId}/transcripts/users-to-notify`);
};

const notifyUsers = ({ projectId, ...body }: Projects.NotifyUsers.Request) => {
  return http.post(`/projects/${projectId}/transcripts/notify-users`, body);
};

export const goals = {
  fetch: fetchProjectGoal,
};

export const links = {
  get: getProjectLink,
  sendSelfSourceInviteEmail: sendSelfSourceProjectInviteEmails,
  sendConsultantRefererInviteEmail: sendConsultantRefererProjectInviteEmail,
};

export const targetCompany = {
  fetch: fetchTargetCompany,
  update: updateProjectTargetCompany,
};