import type * as API from '@api/interfaces';
import type { ProjectFrequency } from '@enums';
import type { Project } from '@/types';

export enum RouteKey {
  Calls = 'calls',
  Highlights = 'highlights',
  Dashboard = 'dashboard',
  Details = 'details',
  Entities = 'entities',
  Files = 'files',
  Pipeline = 'pipeline',
  Summary = 'summary',
  SurveyResponses = 'survey-responses',
  Tags = 'tags',
}

export type TabRouteItem = {
  key: string;
  title: string | React.ReactNode;
  path: string;
};

export type SceneMap = Record<RouteKey, TabScene>;

export type TabScene = {
  component: React.ComponentType;
  path: string;
  title: string | React.ReactNode;
};

export type GroupProjectRouteParams = {
  slug?: string;
  tab?: RouteKey;
} & Partial<Stringify<IProjectId>>;

export type ProjectOverviewFormState = {
  frequency: ProjectFrequency;
  goal: number;
} & Pick<Project,
  | 'description'
  | 'name'
  | 'clientName'>
  & Pick<API.Projects.FetchProject.Response['project'],
    | 'netsuite'
    | 'purchaseOrder'>;