import { useState, useMemo, useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import type { ColumnDef } from '@tanstack/react-table';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TranscriptPublishType } from '@enums';
import type { IWorkspaceTranscriptItem } from '@/types/workspace.table';
import { SortableTable } from '@presentation/Tables';
import { publishTranscripts } from '@api/workspaces.transcript';
import { useToggle } from '@utils';
import { Button } from '@/components/Button';
import { Header } from '@/components/Modal';
import { Checkbox } from '@/components/Checkbox';
import Toast from '@/components/Toast';
import { PublishingCompliance, PublishSettingsSelect } from '@/components/Transcript/Publishing';
import { PublishModalContext } from './context';
import { useUnpublishedTranscripts } from './hooks';
import { usePublishModalNavigateNext } from './PublishModal.Navigation';
import styles from './style/PublishModal.css';

type Props = {
  onClose: () => void;
};

export const Transcripts = (props: Props) => {
  const transcriptItems = useUnpublishedTranscripts();
  const { setNumTranscriptsPublished } = useContext(PublishModalContext);

  const [selectedTranscriptIds, setSelectedTranscriptIds] = useState(transcriptItems.map(i => i.transcript.id));
  const [publishSettingsMap, setPublishSettingsMap] = useState<PublishSettingsMap>(transcriptItems.reduce((acc, x) => ({
    ...acc,
    [x.transcript.id]: x.transcript?.settings?.publishType ?? TranscriptPublishType.RecordingAndTranscript,
  }), {} as PublishSettingsMap));

  const toggleTranscriptCheckmark = useCallback((id: number) => {
    setSelectedTranscriptIds(old => {
      if (old.includes(id)) {
        return old.filter(i => i !== id);
      } else {
        return [...old, id];
      }
    });
  }, []);

  const publishMutation = useMutation({
    mutationFn: () => {
      return publishTranscripts({
        items: selectedTranscriptIds.map(transcriptId => ({
          transcriptId,
          publishType: publishSettingsMap[transcriptId],
        })),
      });
    }, onSuccess: () => {
      setNumTranscriptsPublished(selectedTranscriptIds.length);
    }, onError: () => {
      Toast.error({
        title: 'There was an error publishing the transcripts',
      });
    },
  });

  const handleNext = usePublishModalNavigateNext(props.onClose);
  const [displayCompliance, toggleDisplayCompliance] = useToggle(false);

  const handleSubmit = useCallback(() => {
    publishMutation.mutateAsync().then(() => handleNext());
  }, [
    handleNext,
    publishMutation,
  ]);

  const columns = useMemo<ColumnDef<IWorkspaceTranscriptItem>[]>(() => [
    {
      id: 'check',
      header: 'Select',
      cell: ({ row }) => (
        <Checkbox checked={selectedTranscriptIds.includes(row.original.transcript.id)} onClick={() => toggleTranscriptCheckmark(row.original.transcript.id)} />
      ),
      maxSize: 30,
      meta: {
        className: styles.checkCell,
      },
    },
    {
      id: 'title',
      header: 'Title',
      cell: row => row.row.original.object.name,
    },
    {
      id: 'publish-settings',
      header: 'Publish Settings',
      cell: ({ row }) => {
        const value = publishSettingsMap[row.original.transcript.id];
        return (
          <PublishSettingsSelect
            onChange={e => setPublishSettingsMap(prev => ({
              ...prev,
              [row.original.transcript.id]: e.target.value,
            }))}
            value={value} />
        );
      },
    },
  ], [
    publishSettingsMap,
    setPublishSettingsMap,
    selectedTranscriptIds,
    toggleTranscriptCheckmark,
  ]);

  const table = useReactTable({
    data: transcriptItems,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!displayCompliance) {
    return (
      <>
        <Header>Publish Interviews in this folder?</Header>
        <div>
          <SortableTable table={table} />
        </div>

        <div className={styles.buttons}>
          <Button.Destructive
            onClick={props.onClose}
            variant="brick">
            Cancel
          </Button.Destructive>
          <Button.Affirmative
            onClick={toggleDisplayCompliance}
            variant="brick">
            {`Publish ${selectedTranscriptIds.length} Interviews`}
          </Button.Affirmative>
        </div>
      </>
    );
  }

  return (
    <>
      <PublishingCompliance
        onCancel={toggleDisplayCompliance}
        onSubmit={handleSubmit}
        submitting={publishMutation.isLoading} />
    </>
  );
};

type PublishSettingsMap = Record<number, TranscriptPublishType>;