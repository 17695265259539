import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { Column } from 'react-table';
import { useTable, useFlexLayout, useSortBy, usePagination } from 'react-table';
import type { Projects } from '@api/interfaces/projects';
import * as consts from '@consts';
import { Card, CardContent, Divider, GroupCardHeader } from '@presentation/Main';
import { GroupTableHeaderButton } from '@presentation/GroupTable';
import { getLocationFor } from '@utils';
import { useIsInternalUser } from '@/containers/Store';
import * as Table from '@/components/Table';
import { useProjectsTableColumns } from '@/components/GroupProject/hooks/useProjectsTableColumns';
import styles from './style/ActiveProjectsPanel.css';

const MaxProjects = 5;

function EmptyState() {
  return (
    <div className={styles.empty}>
      <div className={styles.title}>No active projects</div>
      <div className={styles.text}>Create a project to get started</div>
    </div>
  );
}

type Props = {
  data: Projects.FetchProjects.ProjectItem[];
  empty: boolean;
  loading: boolean;
};

export const ActiveProjectsPanel = (props: Props) => {
  const columns = useProjectsTableColumns() as Column<Projects.FetchProjects.ProjectItem>[];
  const isInternalUser = useIsInternalUser();

  const params = {
    columns,
    data: props.data,
    disableSortBy: true,
    initialState: {
      pageIndex: 0,
      pageSize: MaxProjects,
      sortBy: [{
        id: 'createdOn',
        desc: true,
      }],
    },
  };

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<Projects.FetchProjects.ProjectItem>(
    params,
    useFlexLayout,
    useSortBy,
    usePagination,
  );

  const showMore = useMemo(() => props.data.length > MaxProjects, [props.data.length]);

  return (
    <Card>
      <GroupCardHeader className={styles.header}>
        <div>Active Projects</div>
        {isInternalUser && (
          <GroupTableHeaderButton
            title="Create New"
            to={getLocationFor.project.create()} />
        )}
      </GroupCardHeader>
      <CardContent fluid>
        <Table.Root
          EmptyComponent={EmptyState}
          empty={props.empty}
          loading={props.loading}>
          <Table.Header headerGroups={headerGroups} />
          <Table.Body
            {...getTableBodyProps()}
            classes={{
              tbody: styles.body,
              td: styles.td,
            }}
            prepareRow={prepareRow}
            rows={rows} />
          {showMore &&
            <Table.Layout.Footer>
              <div className={styles.footer}>
                <Link to={consts.path.Projects.Root}>View More...</Link>
              </div>
            </Table.Layout.Footer>}
        </Table.Root>
      </CardContent>
    </Card>
  );
};

export default ActiveProjectsPanel;