import { useContext } from 'react';
import { utils } from '@enums';
import { ButtonOutlined } from '@presentation';
import { Button } from '@/components/Button';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import { Pencil } from '@/components/icons/Pencil';
import { FrameContext, ParticipantsContext } from './Context';
import { FrameKey } from './interfaces';
import styles from './style/AttendeeList.css';

type Props = unknown;

export const AttendeeList = (props: Props) => {
  const ctx = {
    frame: useContext(FrameContext),
    participants: useContext(ParticipantsContext),
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.header}>
            <div className={styles.name}>Name</div>
            <div className={styles.role}>Role</div>
          </div>
          <div className={styles.items}>
            {ctx.participants.items.map(x =>
              <div
                className={styles.item}
                key={x.email}>
                <div className={styles.name}>{x.name}</div>
                <div className={styles.role}>{utils.CallRole.getName(x.roleId)}</div>
              </div>)}
          </div>
          <div className={styles.edit}>
            <button
              className={styles.add}
              onClick={() => ctx.frame.goToFrame({ frame: FrameKey.ManageAttendees })}>
              <Pencil className={styles.icon} />
              <span>Edit Attendees</span>
            </button>
          </div>
        </div>
        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="silver"
            onClick={ctx.frame.back}>
            Back
          </ButtonOutlined>
          <Button
            color="primary"
            className={styles.btn}
            variant="brick"
            onClick={() => ctx.frame.goToFrame({ frame: FrameKey.ConferenceSettings })}>
            Next
          </Button>
        </ButtonSet>
      </div>
    </div>
  );
};

AttendeeList.displayName = 'Frame.AttendeeList';