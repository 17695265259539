import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as api from '@api';
import * as consts from '@consts';
import { ButtonActivityIndicator } from '@presentation';
import { cx, qs } from '@utils';
import { AuthForm, Footer } from '$website/components';
import { Spinner } from '@/components/ActivityIndicator';
import LogoTrinity from 'static/images/logo-trinity-white.svg?url';
import styles from './style/Auth0Error.css';

const { Layout } = AuthForm;

type Props = unknown;

export const SetPasswordInviteResult = (props: Props) => {
  const qp = qs.parse<Params>(window.location.search);
  const hash = qs.parse<Hash>(window.location.hash.slice(1));

  const invite = hash?.type === 'invite';
  const failed = qp?.success === 'false';
  const success = qp?.success === 'true';

  const inviteUsed = failed && invite;

  const history = useHistory();

  const query = useQuery([
    `get:users/verify/emails/state`,
    +hash?.userId,
  ], () => {
    return api.users.verify.fetchEmailVerificationState({
      userId: +hash?.userId,
    });
  }, {
    enabled: invite,
  });

  const mutation = useMutation([
    `post:users/verify/emails/password/verify`,
    +qp?.userId,
  ], () => {
    return api.users.verify.verifyEmailFromPasswordChangeInvite({
      userId: +qp?.userId,
    });
  }, {
    onSuccess: () => {
      history.replace(consts.path.Website.PlatformLogin);
    },
  });

  useEffect(() => {

    if (success) {
      mutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.bg}>
        <div className={styles.col}>
          <div className={styles.logo}>
            <img
              src={LogoTrinity}
              alt="Trinity"
              className={styles.logoImage} />
          </div>

          {(success || query.isLoading) &&
            <div className={styles.form}>
              <Spinner />
            </div>}

          {(!success && !query.isLoading && inviteUsed && query.data?.data?.verified) &&
            <div className={styles.generic}>
              <Layout.Header className={styles.header}>
                <Layout.Row className={styles.title}>
                  <Layout.Title>This account has already been verified</Layout.Title>
                </Layout.Row>
              </Layout.Header>

              <div className={styles.main}>
                <ButtonActivityIndicator
                  className={styles.submit}
                  replace
                  to={consts.path.Website.PlatformLogin}>
                  Log In
                </ButtonActivityIndicator>
              </div>
            </div>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

SetPasswordInviteResult.displayName = 'SetPasswordInvite.Result';

type Params = {
  message?: string;
  success?: string;
  userId: string;
};

type Hash = {
  userId: string;
  type: string;
};